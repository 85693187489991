<template>
	<div class="main">
		<div class="tit"><span>安全设置 > 修改邮箱</span></div>
		
		<div class="form">
			<div class="li"><input type="text" v-model="email" class="input1"  placeholder="请输入新邮箱账号"></div>
			<div class="li verifyCode">
				<input type="text" class="input1" v-model="code" placeholder="请输入邮箱收到的验证码" >
				<a  class="btnSend" @click="getCode" ref="btnSend">获取验证码</a>
			</div>
			<p class="p1">邮箱未收到验证码时请联系平台客服</p>
			<a  class="btn1" @click="save">保存</a>
		</div>
		
	</div>
</template>
<script type="text/javascript">
	import icom from '@/common/com.js';
	import utils from '@/common/utils.js';
	import useStore from '@/store/index.js'
	export default {
		data(){
			return {
				email:'',
				code:'',
				countDownTime:'获取验证码',
				logins:{}
			}
		},
		created(){
			this.store = useStore()
			this.logins = this.store.logins;
		},
		components:{
		},
		methods:{
			save(){
				let isEmail = this.checkEmail();
				if(!isEmail) return
				if(this.code==''){
					this.store.showToast('请输入验证码')
					return
				}
				
				this.API.update_email({email:this.email,code:this.code},(res)=>{
					// 提交接口 返回设置页面
					this.$router.push('/person/infoset')
					utils.get_info(this.API,this.store)
				})
				
				
			},
			getCode(){
				let isEmail = this.checkEmail();
				if(!isEmail) return
				// 发送邮件 接口 source 1：绑定邮箱，2：信息认证
				this.API.send_email_code({email:this.email,source:1},(res)=>{
					icom.countdown(this.$refs.btnSend,60);
				})
				
				
				
			},
			checkEmail(){
				if( this.email==''){
					this.store.showToast('请输入邮箱')
					return false
				}
				if(!icom.checkStr(this.email,2)){
					this.store.showToast('请输入正确的邮箱')
					return false
				}
				if( this.email==this.logins.loginInfo.email){
					this.store.showToast('该邮箱为当前绑定的邮箱')
					return false
				}
				return true
			},
			
		}
	}
</script>
<style type="text/css" scoped="">
	.main{flex: 1;background: #FFFFFF;border-radius: 4px;padding: 20px 25px;}
	.form {width: 500px;margin-left: 35px;margin-top: 4px;}
	.form .li {margin-top: 28px;}
	.form .input1 {width: 100%;height: 44px;border-radius: 6px;border: 1px solid #E3E6EC;padding: 0 26px;font-size: 16px;}
	.form .verifyCode {height: auto;overflow: hidden;}
	.form .verifyCode .input1 {width: 318px;float: left;}
	.form .verifyCode .btnSend {width: 143px;height: 44px;background: #F5630B;border-radius: 6px;float: right;;font-size: 16px;color: #fff;text-align: center;line-height: 44px;}
	.form .verifyCode .btnSend.disabled {background: #F3F3F6;color: #222;}
	.form .p1 {margin-top:8px;font-size: 12px;color: #222222;padding-left: 15px;}
	.form .btn1 {width: 360px;height: 44px;background: #F5630B;border-radius: 6px;line-height: 44px;text-align: center;color: #fff;font-size: 18px;margin: 37px auto 0;display: block;}
</style>